<template>
    <div class="ToolClassList">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <el-form :inline="true" class="demo-form-inline" :model="toolClassFram" ref="toolClassFram">
                    <el-form-item label="分类名称">
                        <el-input v-model="toolClassFram.className" placeholder="请输入分类名称查询"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="btn" @click="handleSearch" icon="el-icon-search">查询</el-button>
                        <el-button @click="handleReset" icon="el-icon-refresh-right">重置</el-button>
                    </el-form-item>
                </el-form>
                <div class="clearfix">
                    <el-button class="btn" @click="addToolClass" icon="el-icon-circle-plus-outline">新增</el-button>
                </div>
            </div>

            <!-- 列表 -->
            <el-table :data="toolClassList" border  style="width: 100%; font-size: 13px;"
                :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }">
                <el-table-column prop="seq" label="序号" align="center" width="50px">
                </el-table-column>
                <el-table-column prop="classCode" label="分类编号" align="center"> </el-table-column>
                <el-table-column prop="className" label="分类名称" align="center">
                </el-table-column>
                <el-table-column label="操作" align="center" width="180">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" title="修改" @click="handleEdit(scope.row)">修改</el-button>
                        <el-button type="text" size="mini" title="删除" class="delColor"
                            @click="del(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="toolClassFram.pageNum" :page-sizes="[5, 10, 20, 50, 100]" :page-size="toolClassFram.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-card>
        <!-- 新增表单 -->
        <el-dialog :title="state ? '添加安全工器具信息' : '修改安全工器具信息'" :visible.sync="dialogFormVisible" width="500px"
            :before-close="closeInfo" :close-on-click-modal="false">
            <el-form :model="toolClassInfo" :rules="rules" ref="toolClassInfo">
                <el-form-item label="分类编号" :label-width="formLabelWidth" prop="classCode" v-show="!state">
                    <el-input v-model="toolClassInfo.classCode" autocomplete="off" :readonly="!state"></el-input>
                </el-form-item>
                <el-form-item label="分类名称" :label-width="formLabelWidth" prop="className">
                    <el-input v-model="toolClassInfo.className" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeInfo('toolClassInfo')">取 消</el-button>
                <el-button class="btn" @click="sure('toolClassInfo')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { searchToolClass, delToolClass, addToolClass, updateToolClass } from '@/api/toolClass.js'
export default {
    data() {
        return {
            toolClassList: [],
            toolClassFram: {
                className: null,
                pageNum: 1,
                pageSize: 10
            },
            total: 0,
            toolClassInfo: {
                id: null,
                classCode: null,
                className: null
            },
            rules: {
                className: [{ required: true, message: '请输入分类名称' }],
            },
            formLabelWidth: '80px',
            dialogFormVisible: false,
            state: false
        }
    },
    mounted() {
        this.loadToolClass();
    },
    methods: {
        handleSizeChange(val) {
            this.toolClassFram.pageSize = val;
            this.toolClassFram.pageNum = 1;
            this.loadToolClass();
        },
        handleCurrentChange(val) {
            this.toolClassFram.pageNum = val;
            this.loadToolClass();
        },

        loadToolClass() {
            searchToolClass(this.toolClassFram).then(res => {
                if (res.code === '000000') {
                    this.toolClassList = res.t.list
                    this.total = res.t.total
                    var count = 1;
                    this.toolClassList.forEach((item) => {
                        item.seq = count++
                    })
                }
            })
        },
        //新增
        addToolClass() {
            this.toolClassInfo = {
                id: null,
                classCode: null,
                className: null
            },
                this.dialogFormVisible = true
            this.state = true
        },
        // 修改
        handleEdit(row) {
            this.state = false;
            this.dialogFormVisible = true;
            //不要将当前行的值直接赋值给表单
            // this.form = row;
            this.toolClassInfo = { ...row };
        },

        //提交
        sure(toolClassInfo) {
            this.$refs[toolClassInfo].validate(valid => {
                if (valid) {
                    if (this.state) {
                        //新增
                        addToolClass(this.toolClassInfo).then(res => {
                            if (res.code === '000000') {
                                this.$message.success('添加成功')
                            }

                            this.$refs[toolClassInfo].resetFields()
                            this.dialogFormVisible = false

                            this.loadToolClass()
                        })
                    } else {
                        //修改
                        updateToolClass(this.toolClassInfo).then(res => {
                            if (res.code === '000000') {
                                this.$message.success('修改成功')
                            }
                            this.$refs[toolClassInfo].resetFields()
                            this.dialogFormVisible = false
                            this.loadToolClass()
                        })

                    }

                }
            })
        },

        //关闭
        closeInfo() {
            this.$refs['toolClassInfo'].resetFields();
            this.dialogFormVisible = false;
        },

        //删除
        del({id,className}) {
            this.$confirm('是否删除名称为“'+className+'”的工器具分类？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delToolClass(id).then(res => {
                    if (res.code === '000000') {
                        this.$message.success('删除成功')
                        this.loadToolClass()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                })
            })

        },


        //搜索
        handleSearch() {
            this.loadToolClass(this.toolClassFram);
        },
        //重置
        handleReset() {
            this.toolClassFram = {};
            this.loadToolClass(this.toolClassFram);
        }


    }
}

</script>

<style lang="scss" scoped>
.ToolClassList {

    .el-pagination,
    .clearfix {
        text-align: left;
    }

    .delColor {
        color: red;
    }

}
</style>